@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  /* beige */
  background-color: #f6f3ed;
  /* gray-500 */
  color: #4a4a4a;
  font-size: 15px;
}

a {
  text-decoration: none;
  /* gray-500 */
  color: #4a4a4a;
}

* {
  box-sizing: border-box;
}

.textSideways {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

/* Set a default icon size to prevent full size icon flash on page load */
.MuiSvgIcon-root {
  width: 22.5px;
}

/* Easier to handle imported font families in global than with MUI sx attribute */
.MuiToggleButtonGroup-grouped {
  font-family: Söhne, sans-serif !important;
  font-weight: bold;
}

@font-face {
  font-family: 'Feijoa';
  src: url('/fonts/Feijoa-Bold.otf');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Feijoa';
  src: url('/fonts/Feijoa-Display.otf');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Feijoa';
  src: url('/fonts/Feijoa-Medium.otf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Feijoa';
  src: url('/fonts/Feijoa-MediumItalic.otf');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Söhne';
  src: url('/fonts/Söhne-Kräftig.otf');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Söhne';
  src: url('/fonts/Söhne-Leicht.otf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Söhne';
  src: url('/fonts/Söhne-LeichtKursiv.otf');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

/* Toastify */

.Toastify__toast-body {
  font-family: 'Söhne';
  padding: 20px;
  width: 100%;
}

/* Swiper */

.swiper-button-next,
.swiper-button-prev {
  /* purple-500 */
  color: #532867 !important;
  fill: #532867 !important;
  stroke: #532867 !important;
}

.swiper-wrapper {
  padding-bottom: 30px;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}

.swiper-pagination-bullet {
  background-color: #d9d9d9 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: #532867 !important;
}

/* React Date Picker */

.react-datepicker-wrapper {
  width: 100%;
}

/* Cropper */

.cropper-line {
  background-color: #a678c2 !important;
}

.cropper-point {
  background-color: #a678c2 !important;
  width: 6px !important;
  height: 6px !important;
}
